import { useEffect, useState } from 'react';

import breakpoints from '../styles/theme/breakpoints';
import useWindowSize from './useWindowSize';

// eslint-disable-next-line
export default () => {
  const windowSize = useWindowSize();
  const [isMobile, setIsMobile] = useState(false);
  const [isAboveTablet, setIsAboveTablet] = useState(false);
  const [isAboveDesktop, setIsAboveDesktop] = useState(false);
  const [isAboveXl, setIsAboveXl] = useState(false);
  const [isTablet, setIsTablet] = useState(false);
  const [isDesktop, setIsDesktop] = useState(false);

  useEffect(() => {
    if (!windowSize.width) return;
    setIsMobile(windowSize.width < breakpoints.md);
    setIsAboveTablet(windowSize.width >= breakpoints.sm);
    setIsAboveDesktop(windowSize.width >= breakpoints.xl);
    setIsAboveXl(windowSize.width >= breakpoints.xl);
    setIsTablet(
      windowSize.width >= breakpoints.md && windowSize.width < breakpoints.xl
    );
    setIsDesktop(windowSize.width >= 1024);
  }, [windowSize.width]);

  return {
    isMobile,
    isAboveTablet,
    isAboveDesktop,
    isAboveXl,
    isTablet,
    isDesktop
  };
};
