import React from "react";

import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { Custom } from "lib/assets/images";

const MobileRestricted = () => (
  <StyledRestrictedBackground>
    <Custom.MobileRestricted />
    <Text variant="h6">Switch to a larger display to view content.</Text>
  </StyledRestrictedBackground>
);

const StyledRestrictedBackground = (props) => (
  <Stack
    sx={{
      width: "100%",
      height: "91vh",
      justifyContent: "center",
      color: "rgba(0,0,0,0.5)",
      alignItems: "center",
      marginLeft: "-64px",
      "& h5": { fontSize: "24px" },
      "& svg": {
        height: "60px",
        width: "65px",
      },
    }}
    {...props}
  />
);

const Text = (props) => (
  <Typography sx={{ fontSize: "24px", textAlign: "center" }} {...props} />
);

export default MobileRestricted;
