/* eslint-disable no-undef */
import { useCallback, useEffect, useRef, useState } from "react";

import { getLeft, getStartingPosition, getTop } from "../utils/helpers";
import useWindowDimensions from "./useWindowDimensions";

const usePosition = (settings) => {
  const {
    onPointerUp,
    onDragStart,
    onDragEnd,
    startPosition,
    dimension = 0,
    startOffset,
    onInit,
    onClosed,
    headPosition,
    isDragDisabled = false,
    fixed
  } = settings;

  const [isLeft, setIsLeft] = useState(false);
  const { width } = useWindowDimensions();

  const ref = useRef(null);
  const isClicked = useRef(false);
  const isDragged = useRef(false);
  const keyPressed = useRef(false);

  const positionRef = useRef({
    left: 0,
    top: 0
  });

  const handlePointerDown = (ev) => {
    isClicked.current = true;
    const ele = ev.target;
    ev.stopPropagation();

    if (ev instanceof PointerEvent) {
      keyPressed.current = false;
      ele.setPointerCapture(ev.pointerId);
    } else if (ev instanceof KeyboardEvent) {
      keyPressed.current = true;

      if (ev.key === "Escape") {
        onClosed();
      }

      if (ev.key !== "Enter") {
        return;
      }
    }
  };

  const handlePointerUp = (ev) => {
    isClicked.current = false;
    ev.stopPropagation();

    const ele = ev.target;

    if (ev instanceof PointerEvent) {
      ele.releasePointerCapture(ev.pointerId);
    } else if (ev instanceof KeyboardEvent && ev.key !== "Enter") {
      return;
    }

    if (!isDragged.current) {
      const mainBubble = document.getElementById("main-bubble") || null;
      if (!mainBubble) return;
      onPointerUp?.();
    } else {
      isDragged.current = false;
      onDragEnd?.(positionRef.current);
    }
  };

  const onPointerMove = (e) => {
    if (isClicked.current && ref.current && !keyPressed.current) {
      const mainBubble =
        document.getElementById("main-bubble")?.getBoundingClientRect() || null;

      if (!mainBubble) return;
      if (isDragDisabled) return;

      const pointer = { x: e.clientX, y: e.clientY };
      const xDifference = pointer.x - mainBubble.left;
      const yDifference = pointer.y - mainBubble.top;

      if (
        (xDifference >= 0 &&
          xDifference <= dimension &&
          yDifference >= 0 &&
          yDifference <= dimension) ||
        isDragged.current
      ) {
        const halfWidth = Math.round(dimension / 2);
        const x = e.clientX - halfWidth;
        const y = e.clientY - halfWidth;

        const position = {
          left: getLeft(x, dimension),
          top: getTop(y, dimension)
        };

        if (!isDragged.current) {
          isDragged.current = true;
          onDragStart?.(position);
        }

        positionRef.current = position;
        ref.current.style.cssText += `top: ${position.top}px;left: ${position.left}px;`;
      }
    }
  };

  const setup = useCallback((node) => {
    if (node) {
      ref.current = node;

      node.addEventListener("pointerdown", handlePointerDown, true);
      node.addEventListener("keydown", handlePointerDown, true);
      node.addEventListener("pointerup", handlePointerUp, true);
      node.addEventListener("keyup", handlePointerUp, true);

      node.style.touchAction = "none";
      node.style.cssText += !fixed
        ? `position: absolute;z-index: 9999;${getStartingPosition(
            startPosition,
            startOffset
          )}`
        : "";
      const { left, top } = node.getBoundingClientRect();
      onInit({
        left,
        top
      });
    }
  }, []);

  useEffect(() => {
    if (ref.current)
      ref.current.style.cssText += !fixed
        ? `position: absolute;z-index: 9999;${getStartingPosition(
            startPosition,
            startOffset
          )}`
        : "";
  }, [ref, startOffset]);

  useEffect(() => {
    const leftHandScreenPx = width / 2;
    setIsLeft(headPosition.x <= leftHandScreenPx);
  }, [headPosition, width]);

  useEffect(() => {
    if (fixed || isDragDisabled)
      document.addEventListener("pointermove", onPointerMove);

    return () => {
      if (fixed || isDragDisabled)
        document.removeEventListener("pointermove", onPointerMove);
    };
  }, []);

  return {
    ref,
    setup,
    isLeft
  };
};

export { usePosition };
