const singlePaneLayout = [{ x: 0, y: 0, w: 1, h: 1, i: '0' }];

const doublePaneLayout = [
  { i: '0', y: 0, w: 1, h: 1, minW: 1, x: 0 },
  { i: '1', y: 0, w: 1, h: 1, minW: 1, x: 1 }
];

const triplePaneLayout = [
  { i: '0', x: 0, y: 0, w: 1, h: 1, minW: 1 },
  { i: '1', x: 1, y: 0, w: 1, h: 2, minW: 1 },
  { i: '2', x: 0, y: 1, w: 1, h: 1, minW: 1 }
];

const quadruplePaneLayout = [
  { i: '0', x: 0, y: 0, w: 1, h: 1, minW: 1 },
  { i: '1', x: 1, y: 0, w: 1, h: 1, minW: 1 },
  { i: '2', x: 0, y: 1, w: 1, h: 1, minW: 1 },
  { i: '3', x: 1, y: 1, w: 1, h: 1, minW: 1 }
];

export const getPaneLayouts = (numberOfPanes, sizes = []) => {
  const getPanesByNumber = () => {
    if (numberOfPanes === 1) return singlePaneLayout;
    if (numberOfPanes === 2) return doublePaneLayout;
    if (numberOfPanes === 3) return triplePaneLayout;
    if (numberOfPanes === 4) return quadruplePaneLayout;
    return quadruplePaneLayout;
  };

  const simpleLayouts = getPanesByNumber();
  const mappedLayout = getPanesByNumber().map((pane, index) => {
    if (sizes.length > 2) return pane;
    let correspondingIndex = index;
    if (index === 2) correspondingIndex = 0;
    if (index === 3) correspondingIndex = 1;

    return { ...pane, w: sizes[correspondingIndex] || pane.w };
  });

  return {
    lg: mappedLayout,
    md: mappedLayout,
    sm: simpleLayouts,
    xs: simpleLayouts,
    xxs: simpleLayouts
  };
};

export const HEADER_HEIGHT = 64;
export const PANES_PADDING = 10;

export const buildDefaultLayoutSettings = (
  numberOfPanes,
  height,
  sizes = []
) => {
  const rows = numberOfPanes <= 2 ? 1 : 2;

  let numberOfCols = numberOfPanes === 1 ? 1 : 2;
  if (sizes.length && sizes.length <= 2) {
    const sum = sizes.reduce((partialSum, a) => partialSum + a, 0);
    numberOfCols = sum + (2 - sizes.length);
  }

  return {
    isDraggable: true,
    isResizable: true,
    rows,
    maxRows: rows,
    preventCollision: false,
    allowOverlap: false,
    draggableHandle: '.draggable-area',
    layouts: getPaneLayouts(numberOfPanes, sizes),
    cols: { lg: numberOfCols, md: numberOfCols, sm: 1, xs: 1, xxs: 1 },
    rowHeight: (height - HEADER_HEIGHT - (rows + 1) * PANES_PADDING) / rows
  };
};
